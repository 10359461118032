import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FaqLayout from "../../components/faq-layout"
import locale from "../../locale/en"
import DisinfectantsContent from "../../components/faq-content/disinfectants"

const { faq } = locale

const Disinfectants = () => (
  <Layout>
    <SEO title={faq.disinfectants} />
    <FaqLayout title={faq.disinfectants}>
      <DisinfectantsContent />
    </FaqLayout>
  </Layout>
)

export default Disinfectants
